import type { AttributeOption, CustomAttribute } from '#gql'
import type { Maybe } from '#gql/default'

// TODO: REFACTOR AFTER MAGENTO 2.4.7 UPGARDE - ACCESS TO CUSTOM ATTRIBUTES WILL BE EASIER
export function useRawlplugCustomAttributes(rawlplugAttributes: CustomAttribute[]) {
  const findRawlplugAttributes = (attributeCode: string) => {
    if (!rawlplugAttributes?.length) {
      return
    }

    if (
      !attributeCode
      || rawlplugAttributes == null
      || !Array.isArray(rawlplugAttributes)
    ) {
      return null
    }

    return rawlplugAttributes.find(
      (item: CustomAttribute) =>
        item.attribute_metadata?.code === attributeCode,
    )?.selected_attribute_options?.attribute_option as Maybe<Maybe<AttributeOption>[]> | undefined
  }

  return {
    findRawlplugAttributes,
  }
}
